import styled from 'styled-components';
import { Heading } from 'ui/elements/Heading/styles'
import { breakpoint } from 'ui/elements/ThemeProvider'
import { Button } from 'ui/elements/Button/styles'

export const Program = styled.div`
  border: 1px solid rgb(172, 172, 172);
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  
  ${Heading} {
    text-decoration: underline;
    flex: 1 1 100%;
    text-align: center;
    
    ${breakpoint.sm`
      text-align: left;
      margin-bottom: 0;
      flex: 0 0 70%;
    `}
  }
  
  ${Button} {
    flex: 1 1 100%;
    
    ${breakpoint.sm`
      max-width: 180px;
      flex: 0 0 25%;
    `}
  }
`;


