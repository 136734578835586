import React from 'react';
import Button from 'ui/elements/Button'
import Heading from 'ui/elements/Heading'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { buildPersonaData, slugify } from 'utils/helpers';
import ContentBox from 'widgets/ContentBox';
import Link from 'widgets/Link';

import * as S from './styles';
import Programs from '../Programs';

const Description = ({
  faculty,
}) => {
  const { t } = useTranslation('faculty');
  const { fullName, position } = buildPersonaData(faculty);
  const to = slugify(`${faculty.firstName} ${faculty.lastName}`);

  return (
    <React.Fragment>
      <Heading level={4} color='primary'>
        {fullName}
      </Heading>
      <Heading level={6}>{position}</Heading>

      <S.Description>
        {
          faculty._rawBio && (
            <ContentBox blocks={faculty._rawBio[0]} />
          )
        }
        <Button
          as={Link}
          to={`/the-experience/faculty/${to}`}
          label={t('readMoreFaculty', { faculty: fullName })}
          variant='clean'
          iconName='arrow-right'
        />
      </S.Description>
      {
        !!faculty.programs.length && (
          <Programs data={faculty.programs} />
        )
      }
    </React.Fragment>
  )
};

export default Description;
