import React from 'react';
import PropTypes from 'prop-types';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import Button from 'ui/elements/Button';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'widgets/Link';
import { slugify } from 'utils/helpers';

import { palette } from 'modules/OpenPrograms/utils';

import * as S from './styles';

const Programs = ({
  data,
}) => {
  const { t } = useTranslation('common');

  return (
    <div>
      {
        data.map((program) => {
          const to = slugify(program.title);

          return (
            <S.Program key={to}>
              <OpticalHeading level={6} color='primary'>
                {program.title}
              </OpticalHeading>

              <Button
                as={Link}
                to={`/open-programs/${to}`}
                iconName='arrow-right'
                color={palette.variant}
                fill
              >
                {t('findOutMore')}
              </Button>
            </S.Program>
          )
        })
      }
    </div>
  )
};

Programs.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Programs;
