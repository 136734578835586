import React, { useCallback, useState } from 'react';
import _flow from 'lodash/flow';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _orderBy from 'lodash/orderBy';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { PersonaGrid } from 'components/shared/Persona';

import * as S from './styles';
import Controls from './Controls';
import Description from './Description';
import useFacultyProgramMap from './useFacultyProgramMap';

const Members = () => {
  const { t } = useTranslation(['faculty']);
  const data = useFacultyProgramMap();
  const [faculties, setFaculties] = useState(data);

  const handleControlsChange = useCallback((order, filter) => {
    const [key, dir] = order.value.split('-');
    const list = _flow(
      (d) => {
        if (!filter || !filter.value) return d;

        return _filter(d, (faculty) => _findIndex(faculty.programs, ['id', filter.value]) !== -1);
      },
      (d) => _orderBy(d, [key], [dir]),
    )(data);

    setFaculties(list);
  }, [data]);

  return (
    <React.Fragment>
      <Controls onChange={handleControlsChange} />

      {
        faculties.length ? (
          <PersonaGrid
            data={faculties}
            descriptionRenderer={(faculty) => <Description faculty={faculty} />}
          />
        ) : (
          <S.NoResults>{t('facultyAnnouncedSoon')}</S.NoResults>
        )
      }

    </React.Fragment>
  )
};

export default Members;
