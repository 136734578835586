import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import _map from 'lodash/map';

import { flattenResponse } from 'utils/helpers';

const ReadFilterPrograms = graphql`
  query ReadFilterPrograms {
    allSanityProgram {
      edges {
        node {
          id
          title
          faculty {
            id
          }
        }
      }
    }
  }
`;

const useFacultyProgramFilter = () => {
  const data = useStaticQuery(ReadFilterPrograms);

  return useMemo(() => {
    const programs = flattenResponse(data, 'allSanityProgram');

    return _map(programs, (program) => ({
      label: program.title,
      value: program.id,
    }));
  }, [data]);
};

export default useFacultyProgramFilter
