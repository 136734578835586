import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

import { flattenResponse } from 'utils/helpers';

const ReadFacultyProgramMap = graphql`
  query ReadFacultyProgramMap {
    allSanityFaculty {
      edges {
        node {
          ...PersonaFragment
          dontShowInOverview
        }
      }
    }

    allSanityProgram {
      edges {
        node {
          id
          title
          faculty {
            id
          }
        }
      }
    }
  }
`;

const useFacultyProgramMap = () => {
  const data = useStaticQuery(ReadFacultyProgramMap);

  return useMemo(() => {
    const rawFaculties = flattenResponse(data, 'allSanityFaculty');
    const programs = flattenResponse(data, 'allSanityProgram');
    const faculties = _filter(rawFaculties, (f) => !f.dontShowInOverview);

    return _map(faculties, (faculty) => {
      const facultyPrograms = _reduce(programs, (acc, p) => {
        if (_find(p.faculty, ['id', faculty.id])) {
          return [...acc, p];
        }

        return acc;
      }, []);

      return {
        ...faculty,
        programs: facultyPrograms,
      }
    });
  }, [data]);
};


export default useFacultyProgramMap
