import React from 'react';
import { graphql } from 'gatsby';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import _get from 'lodash/get';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import withLocalization from 'decorators/withLocalization';
import { Section } from 'components/Layout/styles';
import PageLayout from 'components/Layout/PageLayout';
import ContentBox from 'widgets/ContentBox';

import Members from 'modules/Faculty/Members';

const FacultyIndex = ({
  data: rawData,
  location,
}) => {
  const { t, i18n } = useTranslation('faculty');
  const data = _get(rawData, 'sanityFacultyIndex');

  const heroProps = {
    type: 'polygon',
    data,
  }

  return (
    <PageLayout data={data} heroProps={heroProps}>
      <GatsbySeo
        title='Faculties'
        openGraph={{
          title: 'Faculties',
          url: location.href,
          locale: i18n.language,
        }}
      />

      {
        data._rawContent && (
          <Section>
            <ContentBox blocks={data._rawContent} />
          </Section>
        )
      }
      <Section>
        <OpticalHeading level={4} renderAs="h2">
          {t('facultyMembers')}
        </OpticalHeading>
        <Members />
      </Section>
    </PageLayout>
  );
};

export const query = graphql`
  query FacultyIndex($language: String!) {
    sanityFacultyIndex {
      title {
        localized
      }
      hero {
        ...HeroFragment
      }
      _rawContent
    }

    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withLocalization(FacultyIndex);
