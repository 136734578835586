import styled from 'styled-components';

export const Controls = styled.div`
  margin-bottom: 30px;
`;

export const Label = styled.label`
  font-weight: 700;
  font-size: 1.4rem;
  display: block;
  margin-bottom: 5px;
`

export const Description = styled.div`
  margin-bottom: 30px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const NoResults = styled.div`
  text-align: center;
  font-size: 2.5rem;
  padding: 30px 0;
`;
