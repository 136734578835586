import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import loadable from '@loadable/component'

import * as S from './styles';
import useFacultyProgramFilter from './useFacultyProgramFilter';

const Select = loadable(() => import('../../../../packages/ui/src/elements/Form/Select'))

const ListOrders = [
  { value: 'lastName-asc', label: 'Name (A-Z)' },
  { value: 'lastName-desc', label: 'Name (Z-A)' },
];

const DefaultOrder = { value: 'lastName-asc', label: 'Name (A-Z)' };

const Controls = ({
  onChange,
}) => {
  const { t } = useTranslation(['faculty']);
  const ListFilters = useFacultyProgramFilter();
  const [order, setOrder] = useState(DefaultOrder);
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    onChange(order, filter);
  }, [order, filter, onChange])

  return (
    <S.Controls>
      <Row align='end' justify='between'>
        <Col xs={12} md={3}>
          <S.Label>{t('facultyProgramFilter')}</S.Label>
          <Select
            isClearable
            name='programFilter'
            options={ListFilters}
            onChange={setFilter}
          />
        </Col>
        <Col xs={12} md={3}>
          <S.Label>{t('facultyProgramSortBy')}</S.Label>
          <Select
            value={order}
            name='sort'
            options={ListOrders}
            onChange={setOrder}
          />
        </Col>
      </Row>
    </S.Controls>
  );
};

export default Controls;
